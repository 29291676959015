import React from "react"
import Layout from "../components/global/layout"
import SEO from "../components/global/seo"
import SingleProduct from "../components/single-product-page/singleProduct"

import ultraLipBalmProduct from "../images/product-and-package/ul.jpg"
import ultraImage from "../images/products-without-packaging/ultra.png"

import coldIcon from "../images/icons/product-icon/winter.svg"
import outdoorIcon from "../images/icons/product-icon/outdoors.svg"
import everydayIcon from "../images/icons/product-icon/everyday.svg"
import sunIcon from "../images/icons/product-icon/sun.svg"

import BSBronzeAward from "../images/awards/BS-Award-Bronze.png"

// related product images
import intensiveMoisturiserProduct from "../images/product-and-package/inm.jpg"
import cherryMoisturiserProduct from "../images/product-and-package/mc.jpg"
import morrisonLogo from "../images/stockists/morrisons.png"

import amazonLogo from "../images/stockists/amazon.png"

const product = {
  image: ultraLipBalmProduct,
  productImage: ultraImage,
  title: <h1 className="product-overview__heading">Ultra SPF 50+</h1>,
  alt: "Ultra SPF 50+.",
  subTitle: (
    <h2 className="product-overview__sub-heading">
      <br />
      Blistex Ultra SPF 50+ provides very high sun protection to help defend
      your lips from UVA and UVB rays.
    </h2>
  ),
  disclaimer: "",
  awards: [
    { title: "BRONZE and BEAUTY STEAL in LIP BALM", img: BSBronzeAward },
  ],
  icons: [
    { title: "everyday", icon: everydayIcon },
    { title: "outdoors", icon: outdoorIcon },
    { title: "sunny weather", icon: sunIcon },
    { title: "cold weather", icon: coldIcon },
  ],
  overviewCopy: (
    <div>
      <br />
      <p>
        infused with antioxidants, vitamins C & E, it helps seal in moisture to
        keep them hydrated, whilst protecting them from the sun.
      </p>
    </div>
  ),
  listID: "",
  stockists: [
    {
      stockistName: "Amazon",
      stockistLink: "https://www.amazon.co.uk/dp/B09HSB9HPN",
      stockistImage: amazonLogo,
      showLink: "true",
    },
  ],
  whenToUseCopy: (
    <div>
      <p>
        gently massage all over your lips every day. re-apply as often as you
        like, especially if you're out and about in dry, cold or sunny weather.
        perfect for skiing and your summer holidays.
      </p>
    </div>
  ),
  ingredients: (
    <p>
      ozokerite, tridecyl neopentanoate, octyldodecanol, cera microcristallina,
      petrolatum, myristyl myristate, isopropyl myristate, polybutene,
      dimethicone, ethylhexyl methoxycinnamate, butyl methoxydibenzoylmethane,
      diethylamino hydroxybenzoyl hexyl benzoate, benzophenone-3,
      bis-ethylhexyloxyphenol methoxyphenyl triazine, oryzanol, tocopheryl
      acetate, aroma, citric acid, limonene, phenoxyethanol, saccharin, sorbic
      acid, tetrahexyldecyl ascorbate, vanillin.
    </p>
  ),
  faqs: [
    {
      question: "Does Ultra contain a sun protection factor?",
      answer:
        "Yes, Ultra contains SPF 50 providing advanced sun protection to help defend lips from UVA and UVB rays.",
    },
    {
      question:
        "The packaging for Ultra has changed - is the formula still the same?",
      answer:
        "Yes, although the packaging has been updated, the same trusted formulas remain.",
    },
    {
      question:
        "Does this product contain any ingredients derived from animals?",
      answer: "No.",
    },
    {
      question: "Do you sell this product online? ",
      answer:
        "Ultra is available to buy online through key retailers.  Please click on the where to buy link on each product page.",
    },
    {
      question: "Does this product contain perfume?",
      answer: "No, only flavourings.",
    },
    {
      question: "Does this product contain any parabens?",
      answer: "No.",
    },
  ],
  relatedProducts: [
    {
      productName: "intensive moisturiser.",
      productLink: "/intensive-moisturiser",
      productImage: intensiveMoisturiserProduct,
    },
    {
      productName: "Intensive Moisturiser Cherry.",
      productLink: "/intensive-moisturiser-cherry",
      productImage: cherryMoisturiserProduct,
    },
  ],
}

const UltraLipBalm = () => (
  <Layout noHeaderInLayout={false} makeFooterSticky={false}>
    <SEO title="Ultra Lip Balm" />
    <SingleProduct data={product} inStock={true} outOfStockMsg={null} />
  </Layout>
)

export default UltraLipBalm
